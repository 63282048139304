<template>
  <div>
    <div class="mb-4 w-100 p-3 rounded-lg shadow-lg" style="background-color: #151718;">
      <h5 class="text-uppercase">Tell Your New Teammate What You Like</h5>

      <div v-html="TEAMMATES_DESCRIPTION[role]" />
    </div>

    <div v-for="(i, index) in items" :key="i.id" class="mt-4 w-100 p-3 rounded-lg shadow-lg" style="background-color: #151718;">
      <item
        :key="`item-${i.id}`"
        :index="index"
        :role="role"
        :name.sync="i.name"
        :template-id.sync="i.templateId"
        :content.sync="i.content"
        :file.sync="i.file"
        @remove="handleRemove(index)"
      />
    </div>

    <div v-if="items[0].name" class="mt-4 text-center">
      <b-button @click="addMore" variant="outline-success" :disabled="isSending" pill>Add More Assets</b-button>
      <b-button @click="$emit('success', items)" variant="success" class="float-right" :disabled="isSending" pill>
        Create Teammate
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

import { TEAMMATES_DESCRIPTION } from "@/constants/teammates";

import { generateKey } from "@/helpers/utils";

import Item from "./Item";

export default {
  name: "Documents",
  components: { BButton, Item },
  props: {
    role: {
      type: String,
      required: true,
    },

    isSending: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [{ id: generateKey(), name: "", templateId: "", content: "", file: null }],

    TEAMMATES_DESCRIPTION,
  }),
  methods: {
    addMore() {
      this.items.push({ id: generateKey(), name: "", templateId: "", content: "", file: null });
    },

    handleRemove(index) {
      this.items.splice(index, 1);
    },
  },
};
</script>
