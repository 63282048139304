<template>
  <div class="w-100 h-100">
    <div v-if="currentStep === WIZARD.ROLE" class="w-100 h-100 d-flex align-items-center justify-content-center pr-4">
      <spotlight
        @action="nextStepName"
        :commands="TEAMMATES_OPTIONS_COMMAND"
        icon="aiteammate"
        placeholder="Select the role of your new AI teammate"
      />
    </div>

    <div v-else-if="currentStep === WIZARD.NAME" class="mt-4 mr-4 p-3 rounded-lg shadow-lg" style="background-color: #151718;">
      <h5 class="text-uppercase">Name</h5>

      <div class="d-flex align-items-center">
        <img :src="require(`@/assets/icons/aiteammate.svg`)" width="25" height="25" class="mr-2" />

        <b-form-input
          ref="teammate-wizard-input"
          class="teammate-wizard-input flex-grow-1"
          v-model="name"
          placeholder="Insert the name of your AI Teammate"
          @keyup.enter="nextStepDocuments"
        />
      </div>

      <div class="mt-4 text-right">
        <b-button @click="nextStepDocuments" variant="primary" pill>Continue</b-button>
      </div>
    </div>

    <div v-else-if="currentStep === WIZARD.EXAMPLES" class="mt-4 mr-4">
      <documents @success="triggerCreateTeammate" :role="role" :is-sending="isSending" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { CREATE, UPDATE, TRAIN, UPLOAD } from "@/store/actions";

import { BFormInput, BButton } from "bootstrap-vue";

import { TEAMMATES, TEAMMATES_OPTIONS_COMMAND, WIZARD } from "@/constants/teammates";
import { DOCUMENTS_TYPES, TEMPLATES } from "@/constants/documents";

import Spotlight from "@/components/Spotlight/";
import Documents from "@/components/Base/Documents/";

export default {
  name: "TeammateWizard",
  components: { BFormInput, BButton, Spotlight, Documents },
  computed: {
    ...mapGetters("projectsDocuments", ["documentsByParentId", "documentsByName"]),
    projectId() {
      return this.$route.params.projectId;
    },
  },
  data: () => ({
    teammateId: null,
    name: null,
    role: null,

    examplesIds: {},
    examplesFiles: {},

    currentStep: WIZARD.ROLE,

    isSending: false,

    WIZARD,
    TEAMMATES_OPTIONS_COMMAND,
  }),
  watch: {
    currentStep() {
      this.handleFocus();
    },
  },
  methods: {
    ...mapActions("projectsTeammates", { createTeammate: CREATE, updateTeammate: UPDATE }),
    ...mapActions("projectsDocuments", { createDocument: CREATE, updateDocument: UPDATE }),
    ...mapActions("projectsTasksTools", { trainAITeammate: TRAIN }),
    ...mapActions("storage", { uploadFile: UPLOAD }),
    async triggerCreateTeammate(items) {
      this.isSending = true;

      const { projectId, role: type, name, role } = this;
      const { id: teammateId } = await this.createTeammate({ projectId, type, name });

      let folderParent = this.documentsByName({ name: "Teammates" });
      if (!folderParent) folderParent = await this.triggerCreateDocumentFolder({ name: "Teammates" });
      const { id: folderId } = await this.triggerCreateDocumentFolder({ name, parentId: folderParent.id });

      const examplesFiles = {};
      const examplesIds = {};

      for await (const i of items) {
        const { file, ...others } = i;
        let id = null;
        let storageId = null;
        let storageUrl = null;

        if (file) {
          ({ id, storageId, storageUrl } = await this.triggerUploadFile({ name, file, parentId: folderId }));
          examplesIds[id] = true;
          examplesFiles[storageId] = { id: storageId, url: storageUrl };
        } else ({ id } = await this.triggerCreateDocumentFile({ ...others, parentId: folderId }));

        examplesIds[id] = true;
      }

      await this.updateTeammate({ projectId, teammateId, examplesIds, examplesFiles });

      this.teammateId = teammateId;
      this.examplesIds = examplesIds;
      this.examplesFiles = examplesFiles;

      if (role === TEAMMATES.COPYWRITER) this.triggerExtractWritingRules();
      else if (role === TEAMMATES.ILLUSTRATOR) this.triggerExtractDesignRules();

      this.$router.push({ name: "ProjectTeammate", params: { teammateId } });

      this.isSending = false;
    },

    async triggerCreateDocumentFolder({ parentId = null, name }) {
      const { projectId } = this;

      const type = DOCUMENTS_TYPES.FOLDER;
      const position = this.documentsByParentId({ parentId }).length;

      const folder = await this.createDocument({ projectId, parentId, type, position, name });

      return folder;
    },
    async triggerCreateDocumentFile({ parentId = null, templateId, name, content }) {
      const { projectId } = this;

      const type = DOCUMENTS_TYPES.FILE;
      const position = this.documentsByParentId({ parentId }).length;

      const { id: documentId } = await this.createDocument({ projectId, type, parentId, templateId, name, position });

      await this.updateDocument({ projectId, id: documentId, content });

      return { id: documentId };
    },
    async triggerUploadFile({ parentId = null, templateId = TEMPLATES.NONE, name, file }) {
      const { projectId } = this;

      const type = DOCUMENTS_TYPES.FILE;
      const position = this.documentsByParentId({ parentId }).length;

      const { id: storageId, url } = await this.handleUpload(file);

      const storage = { [storageId]: true };

      const { id: documentId } = await this.createDocument({ projectId, type, parentId, templateId, name, position });

      const content = `<img src="${url}" alt="${name}" />`;

      await this.updateDocument({ projectId, id: documentId, content, storage });

      return { id: documentId, storageId, storageUrl: url };
    },

    async triggerExtractWritingRules() {
      const { projectId, teammateId } = this;
      let { examplesIds } = this;
      examplesIds = Object.keys(examplesIds);

      await this.trainAITeammate({ projectId, teammateId, examplesIds });
    },
    async triggerExtractDesignRules() {
      const { projectId, teammateId } = this;
      let { examplesFiles } = this;

      examplesFiles = Object.values(examplesFiles);

      await this.trainAITeammate({ projectId, teammateId, examplesFiles });
    },

    async handleUpload(file) {
      const { projectId } = this;

      const { isSuccess, id, url } = await this.uploadFile({
        projectId,
        file,
        stateCallback: this.stateChanged,
        errorCallback: this.errorCallback,
        uploadedCallback: this.uploadedCallback,
      });

      if (isSuccess) {
        return { id, url };
      }
    },
    handleProgress(snapshot) {
      this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    },
    handleLoading(value) {
      this.isLoading = value;
    },

    stateChanged(snapshot) {
      this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    },
    errorCallback(message) {
      this.isLoading = false;
      this.handleLoading(this.isLoading);
      this.errorMessage = message;
    },
    uploadedCallback() {
      this.isLoading = false;
      this.handleLoading(this.isLoading);
    },

    nextStepName(role) {
      this.role = role;
      this.currentStep = WIZARD.NAME;
    },
    nextStepDocuments() {
      const { name } = this;
      if (!name) return;

      this.currentStep = WIZARD.EXAMPLES;
    },

    async handleFocus() {
      const { role } = this;

      if (role === WIZARD.NAME) {
        await this.$nextTick();
        this.$refs["teammate-wizard-input"].focus();
      }
    },
  },
};
</script>

<style>
.teammate-wizard-input.form-control {
  border-color: #151718 !important;
  background-color: #272a34 !important;
  border: 0;
  font-size: 1.5rem;
  color: #ffffff;
}

.teammate-wizard-input.form-control::placeholder {
  color: #ffffff;
  opacity: 0.5;
}

.teammate-wizard-input:hover,
.teammate-wizard-input:focus,
.teammate-wizard-input:active {
  border: 0 !important;
  box-shadow: none !important;
}
@media screen and (max-width: 768px) {
  .teammate-wizard-input.form-control {
    font-size: 1.2rem;
  }
}
</style>
