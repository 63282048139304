<template>
  <div @mouseenter="isHovering = true" @mouseleave="isHovering = false">
    <div v-if="!isEditing && isHovering" class="float-right">
      <b-button @click="isEditing = true" variant="transparent" class="p-0">
        <img src="@/assets/icons/pen.svg" width="20" height="20" />
      </b-button>

      <b-button @click="$emit('remove')" variant="transparent" class="ml-3 p-0">
        <img src="@/assets/icons/close.svg" width="18" height="18" />
      </b-button>
    </div>

    <h5 class="text-uppercase">Asset {{ index + 1 }}</h5>

    <div v-if="role === TEAMMATES.COPYWRITER || role === TEAMMATES.SUPPORT">
      <form-builder
        v-if="isEditing"
        :key="resetKey"
        :state-machine="stateMachine"
        :components-list="componentsList"
        :custom-validators="{}"
        :next-button="{ text: 'Use Asset', variant: 'outline-primary' }"
        @submit="nextStep"
        class="form-wizard form-wizard-teammate"
      />

      <div v-else>
        <p>
          <strong>{{ name }}</strong>
          <b-badge variant="primary" class="ml-3 pl-3 pr-3 text-white font-weight-normal" pill>{{ templateId }}</b-badge>
        </p>

        <p v-html="textExcerpt(content)" />
      </div>
    </div>

    <div v-else-if="role === TEAMMATES.ILLUSTRATOR">
      <base-upload
        v-if="isEditing"
        @upload="handleUpload"
        :accepted-types="['image/jpeg', 'image/png']"
        extensions="image (.jpg, .jpeg, .png)"
      />

      <div v-else>
        <p>
          <strong>{{ name }}</strong>
        </p>
        <b-img :src="blob" rounded fluid width="500" />
      </div>
    </div>
  </div>
</template>

<script>
import { BImg, BBadge, BButton } from "bootstrap-vue";
import { FormBuilder } from "ui-stepper";
import cloneDeep from "lodash/cloneDeep";

import { WIZARD_DOCUMENT } from "@/constants/documents";
import { TEAMMATES } from "@/constants/teammates";

import { generateKey, textExcerpt } from "@/helpers/utils";

import BaseUpload from "@/components/Forms/Base/BaseUpload";

export default {
  name: "Item",
  components: { BImg, BBadge, BButton, FormBuilder, BaseUpload },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    role: {
      type: String,
      required: true,
    },
    templateId: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
  },
  computed: {
    localTemplateId: {
      get() {
        return this.templateId;
      },
      set(value) {
        this.$emit("update:template-id", value);
      },
    },
    localName: {
      get() {
        return this.name;
      },
      set(value) {
        this.$emit("update:name", value);
      },
    },
    localContent: {
      get() {
        return this.content;
      },
      set(value) {
        this.$emit("update:content", value);
      },
    },
    localFile: {
      get() {
        return this.file;
      },
      set(value) {
        this.$emit("update:file", value);
      },
    },
  },
  data: () => ({
    blob: null,

    stateMachine: null,

    componentsList: {
      Message: () => ({
        component: import("@/components/Forms/TipTap/Paragraph.vue"),
        timeout: 3000,
      }),
    },

    resetKey: generateKey(),

    isEditing: true,
    isHovering: false,

    TEAMMATES,
  }),
  created() {
    const { role } = this;
    this.stateMachine = cloneDeep(WIZARD_DOCUMENT[role].stateMachine);
  },
  methods: {
    nextStep() {
      const { formData } = this.stateMachine.context;

      const { templateId, name, content } = cloneDeep(formData);

      this.localTemplateId = templateId;
      this.localName = name;
      this.localContent = content;

      this.isEditing = false;
    },

    handleUpload(file) {
      const blobUrl = URL.createObjectURL(file);

      this.blob = blobUrl;
      console.log("file", file);
      console.log("file.name", file.name);
      this.localName = file.name;
      this.localFile = file;

      this.isEditing = false;
    },

    textExcerpt,
  },
};
</script>

<style lang="scss">
.form-wizard-teammate {
  .btn-primary {
    // border: 1px solid #11cdf0;
    background-color: transparent !important;
  }
}
</style>
